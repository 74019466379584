import moment from "moment"
export default [
  {
    name: "idcandidat",
    title: '<i class="grey user outline icon"></i>Id',
    width: "5%"
  },
  {
    name: "nom",
    title: '<i class="grey user outline icon"></i>nom',
    width: "15%"
  },
  {
    name: "prenom",
    title: '<i class="grey user outline icon"></i>prenom',
    width: "15%"
  },
  {
    name: "specialite",
    title: '<i class="grey mail outline icon"></i>specialite',
    width: "10%"
  },
  {
    name: "pays",
    title: '<i class="grey sitemap icon"></i>pays',
    width: "7%"
  },
  {
    name: "remarque",
    title: '<i class="grey birthday icon"></i>remarque',
    width: "15%"
  },
  {
    name: "email",
    title: '<i class="grey email icon"></i>email',
    titleClass: "center aligned",
    dataClass: "center aligned",
    width: "15%"
  },
  {
    name: "facebook",
    title: '<i class="grey money icon"></i>facebook',
    titleClass: "center aligned",
    dataClass: "right aligned",
    width: "15%"
  },
  {
    name: "linkedin",
    title: '<i class="grey money icon"></i>linkedin',
    titleClass: "center aligned",
    dataClass: "right aligned",
    width: "15%"
  },
  {
    name: "datemessage",
    title: '<i class="grey money icon"></i>Date',
    titleClass: "center aligned",
    dataClass: "right aligned",
    width: "10%",
    formatter: (value) => {
      return (value === null)
        ? ''
        : moment(value, 'YYYY-MM-DD').format('MMM Do, YYYY')
    }
  }
]