<template>
  <div id="app">
    <header>
      <Navbar />
    </header>
    <div class="row">
      <vuetable ref="vuetable"
        api-url="http://suivi.networld-tn.com/dao/test.php"
        :fields="fields"
        data-path="data"
        pagination-path=""        
         :per-page="20"
        @vuetable:pagination-data="onPaginationData"
      ></vuetable>
      <div class="pagination ui basic segment grid">
         <vuetable-pagination-info ref="paginationInfo"
         ></vuetable-pagination-info>
      
         <vuetable-pagination ref="pagination"
           @vuetable-pagination:change-page="onChangePage"
         ></vuetable-pagination>
       </div>
    </div>
  
  </div>
</template>

<script>
import Navbar from './components/Navbar';
//import VueHighcharts from 'vue-highcharts';
import Vuetable from 'vuetable-2';
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo';
import FieldsDef from "./FieldsDef.js";
//import Vue from 'vue';
// Use the VueHighcharts plugin

export default {
  name: 'app',
  //use : VueHighcharts,
  components: {
    Navbar,
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo
  
  },
  data() {
    return {
      fields: FieldsDef

    };
  },
  methods: {
  //...
  // when the pagination data is available, set it to pagination component
  onPaginationData (paginationData) {
    this.$refs.pagination.setPaginationData(paginationData)
    this.$refs.paginationInfo.setPaginationData(paginationData);
  },
  // when the user click something that causes the page to change,
  // call "changePage" method in Vuetable, so that that page will be
  // requested from the API endpoint.
  onChangePage (page) {
    this.$refs.vuetable.changePage(page)
  }
}
}

</script>

<style>
* {
  margin: 20;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 20;
  font-family: 'montserrat', sans-serif;
}
header {
  width: 100vw;
  background-color: #222;
  padding: 15px;
}.pagination {
    margin-top: 1rem;
  }
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;
}
</style>