<template>
  <nav v-once>
    <div class="menu-item"><a href="candidat2021.php">Candidat 2021</a></div>
    <div class="menu-item"><a href="abonnees2021.php">Abonnées 2021</a></div>
    <Dropdown title="Liste de candidat" :items="services" />
    <div class="menu-item"><a href="formulaire.php">Liste formulaire</a></div>
    <div class="menu-item"><a href="email.php">Email</a></div>
  </nav>
</template>

<script>
import Dropdown from './Dropdown';
export default {
  name: 'navbar',
  components: {
    Dropdown
  },
  data () {
    return {
      services: [
        {
          title: 'spécialite',
          link: '#'
        },
        {
          title: 'Design',
          link:'#'
        },
        {
          title: 'Videos',
          link: '#'
        }
      ]
    }
  }
}
</script>

<style>
nav {
  display: flex;
  align-items: center;
  justify-content: center;
}
nav .menu-item {
  color: #FFF;
  padding: 10px 20px;
  position: relative;
  text-align: center;
  border-bottom: 3px solid transparent;
  display: flex;
  transition: 0.4s;
}
nav .menu-item.active,
nav .menu-item:hover {
  background-color: #444;
  border-bottom-color: #FF5858;
}
nav .menu-item a {
  color: inherit;
  text-decoration: none;
}
</style>